<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <base-material-card color="success" inline title="Admin" class="px-5 py-3">
          <v-form>
            <v-container class="py-0" fluid>
              <span v-if="!record.token" class="d-none">{{ (record.token = generateToken(32)) }}</span>
              <span v-if="!record.company" class="d-none">{{ (record.company = companyId) }}</span>
              <span v-if="!record.settings.app_type" class="d-none">{{ (record.settings.app_type = "ebib") }}</span>
              <v-row dense class="text-no-wrap" align="center">
                <v-col cols="12" sm="8" class="order-1 order-sm-0">
                  <v-row :dense="dense" class="text-no-wrap" align="center">
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.fullname"
                        :rules="inputValidate"
                        style="width:300px"
                        :hide-details="hided"
                        :dense="dense"
                        :label="$t('Nume prenume')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.email"
                        style="width:300px"
                        :rules="inputValidate"
                        :hide-details="hided"
                        :dense="dense"
                        :label="$t('Email')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="record.username"
                        :rules="inputValidate"
                        style="width:300px"
                        :hide-details="hided"
                        :dense="dense"
                        :label="$t('Utilizator')"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        :value="dictionary.pass"
                        style="width:300px"
                        :hide-details="hided"
                        :dense="dense"
                        type="password"
                        :label="$t('Parolă')"
                        @input="
                          dictionary.pass = $event
                          $event ? (record.password = md5($event)) : (record.password = '')
                        "
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="3" class="d-flex justify-center order-0 order-sm-1">
                  <add-image v-model="record.img" :height="150" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-select
                    v-model="record.settings.user_type"
                    style="width:300px"
                    :hide-details="hided"
                    :dense="dense"
                    item-text="name"
                    item-value="value"
                    :label="$t('Tip')"
                    :items="dictionary.user_type"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <s-autocomplete
                    v-model="record.settings.menu_type"
                    :where="'&quot;owner.user_type&quot;:&quot;' + record.settings.user_type + '&quot;,'"
                    :label="$t('Tip meniu')"
                    :hide-details="hided"
                    :dense="dense"
                    :searchmin-length="0"
                    :item-text="'menu_type'"
                    :item-value="'menu_type'"
                    collection="app_menu"
                    @changedValue="$set(dictionary, 'userMenuID', $event)"
                  />
                  <!-- <v-text-field
                    v-model="record.settings.menu_type"
                    style="width:300px"
                    :hide-details="hided"
                    :dense="dense"
                    :label="$t('Tip meniu')"
                  /> -->
                </v-col>
                <v-col cols="12" sm="4">
                  <v-checkbox
                    v-if="userType == 'superadmin'"
                    v-model="record.settings.debug"
                    class="mt-0"
                    :hide-details="hided"
                    :dense="dense"
                    :label="$t('Depanare')"
                  />
                </v-col>
              </v-row>

              <base-material-tabs
                :background-color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
                color="primary"
                show-arrows
                center-active
                centered
                fixed-tabs
              >
                <v-tab>{{ $t("Circulație") }}</v-tab>
                <v-tab>{{ $t("Meniu") }}</v-tab>
                <v-tab-item class="pt-4">
                  <v-row>
                    <v-col cols="12">
                      <span class="d-none">{{ render }}</span>
                      <v-autocomplete
                        :value="record.library.sites"
                        :hide-details="hided"
                        multiple
                        chips
                        deletable-chips
                        :return-object="false"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :label="$t('Gestiuni')"
                        :items="dictionary.gestiune"
                        @input="record.library.sites = $event"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="pt-4">
                  <v-treeview selected-color="primary" item-text="title" item-children="items" :items="record.menu">
                    <template v-slot:label="props">
                      <v-checkbox
                        v-model="props.item.enabled"
                        class="mt-0 pt-0"
                        :label="props.item.title"
                        dense
                        hide-details
                      />
                      <!-- <div class="v-treeview-node__content">
                        <div class="v-treeview-node__label">
                          {{ props.item.title }}
                        </div>
                      </div> -->
                    </template>
                  </v-treeview>
                </v-tab-item>
              </base-material-tabs>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
    <search-link ref="searchLink" select-event @select="setLinkValue" />
    <create-edit ref="createEdit" />
  </v-container>
</template>

<script>
import SearchLink from "../../../components/containers/SearchLink"
import CreateEdit from "../../../views/settings/AddDictionaryEdit"
import md5 from "js-md5"
// import SDateTime from "../../../components/inputs/DateTime"
import SAutocomplete from "../../../components/inputs/Autocomplete"
import AddImage from "../../../components/containers/AddImage"
import { EventBus } from "../../../EventBus"
import axios from "../../../plugins/axios"
//import axio from "axios"
export default {
  name: "DashboardDashboard",
  components: {
    SearchLink,
    CreateEdit,
    // SDateTime,
    AddImage,
    SAutocomplete
  },
  data() {
    return {
      dense: true,
      hided: false,
      fileName: "",
      file: {},
      urlImg: null,
      searchId: "",
      dictionary: {},
      linkArray: null,
      linkField: null,
      linkSubfield: null,
      linkIndex: null,
      selectId: null,
      render: 1,
      record: {
        settings: {},
        library: {
          sites: [
            "AV - Secţia audiovizual",
            "BD - Birou direcţiune",
            "CT - Filiala Calea Traian",
            "BI - Birou informatizare"
          ]
        }
      },
      recordView: {
        settings: {},
        library: {
          sites: [
            "AV - Secţia audiovizual",
            "BD - Birou direcţiune",
            "CT - Filiala Calea Traian",
            "BI - Birou informatizare"
          ]
        }
      },
      schema: {
        dictionary: ["all.gestiune", "all.user_type"]
      },
      rows: []
    }
  },

  computed: {
    inputValidate() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    },
    companyId() {
      return this.$store.getters.companyId
    }
  },
  created() {
    if (this.schema.dictionary) {
      this.schema.dictionary.map(itm => this.getDictionaryValues(itm))
    }
    const country = []
    this.rows.map(itm => {
      country.push({ name: itm.value, value: itm.value })
    })
    EventBus.$on("dictionary-reload", dict => {
      this.$log("dictionary-reload ", dict)
      if (this.selectId == dict.id) {
        this.getDictionaryValues("all." + dict.dictionary)
        if (dict.select) {
          this.setLinkValue(dict.select)
        }
      }
    })
    // EventBus.$on("table-form-select", value => {
    //   this.$log("selected ", value)
    // })
    //this.$log(JSON.stringify(country))
    // axios.post("anaf_check_tva", [{ cui: 18158683, data: "2020-04-30" }]).then(response => {
    //   this.$log("response ", response)
    // })
  },
  methods: {
    generateToken(length) {
      //edit the token allowed characters
      var a = "abcdef1234567890".split("")
      var b = []
      for (var i = 0; i < length; i++) {
        var j = (Math.random() * (a.length - 1)).toFixed(0)
        b[i] = a[j]
      }
      return b.join("")
    },
    createURL(img, fil) {
      this.$set(this.dictionary, img, URL.createObjectURL(fil.target.files[0]))
    },
    fileChanged(e) {
      this.file = e.target.files[0]
      this.urlImg = URL.createObjectURL(this.file)
      this.fileName = this.file.name
    },
    clearFile() {
      this.fileName = ""
      this.file = null
    },
    createAndEdit(e, arr, field, ind) {
      //searchRelation(arr, field, ind, res, fld) {
      this.linkArray = arr
      this.linkField = field
      this.linkIndex = ind
      this.selectId = Date.now()
      this.$log("createAndEdit ", e)
      this.$refs.createEdit.showData(e, this.selectId)
    },
    checkTVA(e) {
      this.$log("createAndEdit ", md5("ke"))
      if (e.length > 5) {
        const dat = new Date()
        axios.post("anaf_check_tva", [{ cui: e, data: dat.toJSON().split("T")[0] }]).then(response => {
          if (response.data.found.length > 0) {
            this.record.invoice.tva = response.data.found[0].scpTVA
          }
        })
      }
    },
    loadCUIData(e) {
      if (e.length > 5) {
        const dat = new Date()
        axios.post("anaf_check_tva", [{ cui: e, data: dat.toJSON().split("T")[0] }]).then(response => {
          if (response.data.found.length > 0) {
            this.record.invoice.tva = response.data.found[0].scpTVA
            this.record.name = response.data.found[0].denumire
            const adr = response.data.found[0].adresa.split(",")
            if (adr.length > 2) {
              this.record.address.address = response.data.found[0].adresa.replace(adr[0] + "," + adr[1] + ",", "")
            }
            this.record.address.locality = adr[1]
            this.record.address.country = "România"
            if (adr[0].substr(0, 3) == "JUD") {
              const jud = adr[0].substr(5).toLowerCase()
              this.record.address.county = adr[0].substr(5)
              this.dictionary.county.map(itm => {
                if (itm.value.toLowerCase() == jud) {
                  this.record.address.county = itm.value
                }
              })
            } else {
              this.record.address.county = "Bucureşti"
            }
          }
        })
      }
    },
    getdictionary(dic) {
      return this.$store.getters.dictionary(dic)
    },
    getDictionaryValues(dic) {
      //this.$log("getDictionaryValues(itm) ", dic)
      const dictionary = dic.split(".")
      if (dictionary[0] == "all") {
        //axios.get('app_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
        this.$set(this.dictionary, dictionary[1], this.getdictionary("all_" + dictionary[1]))
        // this.dictionary[dictionary[1]] = response.data._items[0].content
        //})
      } else if (dictionary[0] == "system") {
        axios.get('app_system_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
          this.$set(this.dictionary, dictionary[1], response.data._items[0].content)
          // this.dictionary[dictionary[1]] = response.data._items[0].content
        })
      }
    },
    addSubItem(e, ind) {
      if (ind == null) {
        this.resolvePath(e, this.record).push(JSON.parse(JSON.stringify(this.resolvePath(e, this.recordView)[0])))
      } else {
        this.resolvePath(e, this.record).splice(
          ind,
          0,
          JSON.parse(JSON.stringify(this.resolvePath(e, this.recordView)[0]))
        )
      }
    },
    delSubItem(e, ind) {
      this.resolvePath(e, this.record).splice(ind, 1)
      if (this.resolvePath(e, this.record).length == 0) {
        this.addSubItem(e)
      }
    },
    searchRelationMarc(field, subfield, ind, prefix, ctg) {
      this.linkField = field
      this.linkSubfield = subfield
      this.linkIndex = ind
      //this.$log("srcrel ", this.schemaContent[fields.field + subfield])
      this.searchId = Date.now()
      let where = ""
      let vTable = ""
      if (ctg) {
        where = '"ctg":' + '"' + ctg + '"'
        const ctgview = this.$store.getters.appViewTableCollectionCategory("marc_" + prefix, ctg)
        if (ctgview.length > 0) {
          vTable = ctgview[0]._id
        }
      }
      this.$refs.searchLink.open("ebib_marc_" + prefix, null, this.searchId, {
        where: where,
        viewTable: vTable,
        ctg: ctg
      })
      // EventBus.$emit("search-link", {
      //   resource: "ebib_marc_" + prefix,
      //   field: null,
      //   id: this.searchId,
      //   config: { where: where, viewTable: vTable, ctg: ctg }
      // })
      //this.$refs.searchLink.open(res, fld, this.searchId)
    },
    searchRelation(arr, field, ind, res, fld) {
      this.linkArray = arr
      this.linkField = field
      this.linkIndex = ind
      this.searchId = Date.now()
      this.$refs.searchLink.open(res, fld, this.searchId)
    },
    setLink(field, subfield, ind, prefix, ctg, e) {
      this.$log("eeee ", e)
    },
    setLinkValue(e) {
      if (this.linkIndex != null) {
        if (this.linkField != null) {
          //this.linkArray[this.linkIndex][this.linkField] = e
          this.$set(this.linkArray[this.linkIndex], this.linkField, e)
        } else {
          if (typeof e === "object") {
            e.iiid = this.idGlobal++
          }
          if (this.linkIndex == -1) {
            this.linkArray.push(e)
          } else {
            //this.linkArray[this.linkIndex] = e
            this.$set(this.linkArray, this.linkIndex, e)
          }
        }
      } else {
        //this.linkArray[this.linkField] = e
        this.$set(this.linkArray, this.linkField, e)
        this.render = Date.now()
      }
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>
